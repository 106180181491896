import React, { useState } from 'react';
import { request } from '../utils/network-client';
import { setJwtTokenCookie } from '../utils/authentication';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginIsInvalid, setLoginIsInvalid] = useState(false);

  const invalidLogin = () => {
    setUsername('');
    setPassword('');
    setLoginIsInvalid(true);
  };

  const loginUser = (e) => {
    e.preventDefault();

    request(
      'post',
      'login',
      undefined,
      {
        username: username,
        password: password,
      },
      false,
    ).then((resp) => {
      switch (resp.status) {
        case 200:
          setJwtTokenCookie(resp.data.accessToken);
          window.location.href = '/'; // use location.href instead of useNavigate to refresh the page after login (we need this in order to refresh the navbar as well)
          break;
        case 403:
        case 401:
          invalidLogin();
          break;
        default:
          invalidLogin();
      }
    });
  };

  return (
    <form className="mb-4 mb-md-0" onSubmit={loginUser}>
      <div className="mb-3">
        <label htmlFor="username" className="form-label">
          Username
        </label>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            <i className="bi-person"></i>
          </span>
          <input type="text" className={`form-control ${loginIsInvalid && 'is-invalid'}`} id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="password" className="form-label">
          Password
        </label>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            <i className="bi-key"></i>
          </span>
          <input
            type="password"
            className={`form-control ${loginIsInvalid && 'is-invalid'}`}
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
      <button type="submit" className="form-control btn btn-primary">
        <i className="bi-box-arrow-in-right"></i> Login
      </button>
    </form>
  );
};

export default Login;
