import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ExternalRedirect = ({ to }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.open(to, '_blank');
    navigate(-1); // navigate the react app back to the previous path

    // eslint-disable-next-line
  }, [to]);

  return null;
};

export default ExternalRedirect;
