import { useEffect, useRef, useState } from 'react';
import HLS from 'hls.js';
import { request } from '../../utils/network-client';
import './style.css';

const AudioPlayer = ({ streamPath }) => {
  const [streamUrl, setStreamUrl] = useState(null);

  const hls = useRef(null);
  const audioRef = useRef(null);

  const generateStreamUrl = async () => {
    const resp = await request('post', `streams/generateSign`, undefined, { streamPath: streamPath });
    const sign = resp.data;
    setStreamUrl(`${process.env.REACT_APP_API_BASE_URL}/converted_streams${streamPath}/index.m3u8?sign=${sign}`);
  };

  useEffect(() => {
    if (!streamUrl) {
      generateStreamUrl();
      return;
    }

    if (audioRef.current) {
      if (HLS.isSupported()) {
        hls.current = new HLS();
        hls.current.attachMedia(audioRef.current);
      } else if (audioRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        // For Safari, native HLS support
        audioRef.current.src = streamUrl;
      }
    }

    return () => {
      if (hls.current) {
        hls.current.stopLoad();
      }
    };

    // eslint-disable-next-line
  }, [streamUrl]);

  const handlePlay = () => {
    if (audioRef.current && hls.current) {
      hls.current.loadSource(streamUrl);
      audioRef.current.play();
    }
  };

  const handleStop = () => {
    if (audioRef.current && hls.current) {
      audioRef.current.pause();
      hls.current.stopLoad();
    }
  };

  return <audio ref={audioRef} controls onPlay={handlePlay} onPause={handleStop} className="audio-player" />;
};

export default AudioPlayer;
