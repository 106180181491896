import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculateActiveTime } from '../../utils/utils';

const StreamCard = ({ id, title, streamerIp, listenersCount, startedOn }) => {
  const [upTime, setUptime] = useState(calculateActiveTime(startedOn));

  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setUptime(calculateActiveTime(startedOn));
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [startedOn]);

  const navigateToStreamDetails = (id) => {
    navigate(`/streams/${id}`);
  };

  return (
    <div className="col">
      <div className="card h-100 cursor-pointer" onClick={() => navigateToStreamDetails(id)}>
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <div className="card-text">
            <div>id: {id}</div>
            <div>ip: {streamerIp}</div>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-between">
          <span className="badge rounded-pill text-bg-warning">{listenersCount} listeners</span>
          <span className="badge rounded-pill text-bg-info">{upTime}</span>
        </div>
      </div>
    </div>
  );
};

export default StreamCard;
