import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getJwtTokenCookie } from './utils/authentication';
import Login from './components/Login';

import towerImage from './assets/images/tower.jpg';

const Home = () => {
  document.title = `Home | Govori Kula`;

  const navigate = useNavigate();
  useEffect(() => {
    const jwtToken = getJwtTokenCookie();

    if (jwtToken) {
      navigate('/streams');
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="row d-flex align-items-center align-items-stretch">
        <div className="col-md order-2 order-md-1">
          <img src={towerImage} className="img-fluid rounded" alt="tower on sunset" />
        </div>
        <div className="col-md order-1 order-md-2 d-flex flex-column justify-content-between">
          <div>
            <h1>Govori Kula</h1>
            <span className="fw-light">Admin panel of Govori Kula</span>
          </div>

          <p>Live audio streaming platform, providing real-time capture and transmission of air traffic control communications.</p>

          <Login />

          <p className="align-self-end">
            A project of{' '}
            <a href="https://visoko.bg/" target="_blank" rel="noreferrer">
              visoko.bg
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Home;
