export const calculateActiveTime = (startTimestamp) => {
  const startedOnDate = new Date(startTimestamp);
  const now = new Date();
  const diff = now - startedOnDate; // Difference in milliseconds

  const millisecondsInSecond = 1000;
  const millisecondsInMinute = millisecondsInSecond * 60;
  const millisecondsInHour = millisecondsInMinute * 60;
  const millisecondsInDay = millisecondsInHour * 24;

  const days = Math.floor(diff / millisecondsInDay);
  const hours = Math.floor((diff % millisecondsInDay) / millisecondsInHour);
  const minutes = Math.floor((diff % millisecondsInHour) / millisecondsInMinute);
  const seconds = Math.floor((diff % millisecondsInMinute) / millisecondsInSecond);

  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};
