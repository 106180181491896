import React, { useEffect, useState } from 'react';
import { request } from './utils/network-client';
import { useNavigate, useParams } from 'react-router-dom';
import { getJwtTokenCookie } from './utils/authentication';
import ListenersList from './components/ListenersList';
import Spinner from './components/Spinner';
import AudioPlayer from './components/AudioPlayer';
import { calculateActiveTime } from './utils/utils';
import Recordings from './components/Recordings';

const FETCH_INTERVAL = 10 * 1000; // 10 seconds

const StreamDetails = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const jwtToken = getJwtTokenCookie();

    if (!jwtToken) {
      navigate('/');
    }

    // eslint-disable-next-line
  }, []);

  const { id } = useParams(); // get parameter from url

  const [stream, setStream] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [streamActiveTime, setStreamActiveTime] = useState();

  // Set window title
  useEffect(() => {
    if (stream) {
      document.title = `Stream ${stream.name} | Govori Kula`;
    }
  }, [stream]);

  // Fetch streams
  useEffect(() => {
    const fetch = (showLoading = false) => {
      showLoading && setLoading(true);

      request('get', `streams/${id}`)
        .then((resp) => {
          setStream(resp.data);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetch(true);
    const intervalId = setInterval(fetch, FETCH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [id]);

  // Calculate the active time
  useEffect(() => {
    if (!stream) return;

    // initial set
    setStreamActiveTime(calculateActiveTime(stream.startedOn));

    const intervalId = setInterval(() => {
      setStreamActiveTime(calculateActiveTime(stream.startedOn));
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [stream]);

  return (
    <>
      <h1>Stream {stream?.name}</h1>

      {loading && <Spinner />}

      {stream && (
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <h2>Details</h2>
              Stream name: {stream.name} <br />
              Stream Id: {stream.id} <br />
              Active time: {streamActiveTime} <br />
              <hr />
              <Recordings streamName={stream.name} />
              <hr />
              <h2>Listen to the stream</h2>
              <AudioPlayer streamPath={stream.path} />
              <hr className="d-block d-lg-none" />
            </div>
            <div className="col-lg">
              <ListenersList listeners={stream.listeners} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StreamDetails;
