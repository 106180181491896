import { useEffect } from 'react';
import { logout } from '../utils/authentication';

const Logout = () => {
  useEffect(() => {
    logout();
  }, []);
};

export default Logout;
