import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';

import Login from './Login';
import Streams from './Streams';
import StreamDetails from './StreamDetails';
import Logout from './components/Logout';
import ExternalRedirect from './components/ExternalRedirect';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <Navbar />

      <div className="container mt-4">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/streams" element={<Streams />} />
          <Route path="/streams/:id" element={<StreamDetails />} />
          <Route path="node-media-server" element={<ExternalRedirect to={'https://kula.visoko.dev:8443/admin/'} />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </div>
    </HashRouter>
  </React.StrictMode>,
);
