import StreamCard from './StreamCard';
import './style.css';

const StreamsList = ({ streams }) => {
  const renderStreams = () => {
    return streams.map((stream) => {
      return (
        <StreamCard
          key={stream.id}
          id={stream.id}
          title={stream.name}
          streamerIp={stream.streamerIp}
          listenersCount={stream.listenersCount}
          startedOn={stream.startedOn}
        />
      );
    });
  };

  return (
    <>
      <div className="row row-cols-1 row-cols-md-3 g-4">{renderStreams()}</div>
    </>
  );
};

export default StreamsList;
