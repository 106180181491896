import './style.css';

const Spinner = ({ fullscreen }) => {
  return (
    <div className={`spinner-container ${fullscreen && 'spinner-container-fullscreen'}`}>
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
