import moment from 'moment';
import { useState } from 'react';
import { downloadFile } from '../../utils/network-client';

const RecordingRow = ({ recording }) => {
  const [loading, setLoading] = useState(false);

  const download = (filePath) => {
    setLoading(true);
    downloadFile(filePath).finally(() => {
      setLoading(false);
    });
  };

  return (
    <tr>
      <td>{moment(recording.startedAt).format('DD MMM YYYY | HH:mm')}</td>
      <td>
        <button className={`btn btn-link ${loading && 'disabled'}`} onClick={() => download(recording.downloadPath)}>
          Download
        </button>
      </td>
    </tr>
  );
};

export default RecordingRow;
