import ListenerRow from './ListenerRow';

const ListenersList = ({ listeners }) => {
  const renderListeners = () => {
    return listeners.map((listener) => {
      return (
        <ListenerRow key={listener.id} id={listener.id} ip={listener.ip} type={listener.type} serialNumber={listener.serialNumber} connectedOn={listener.connectedOn} />
      );
    });
  };

  return (
    <>
      <h2>Listeners ({listeners.length})</h2>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Ip</th>
              <th scope="col">Type</th>
              <th scope="col">Serial number</th>
              <th scope="col" style={{ minWidth: '160px' }}>
                Active time
              </th>
            </tr>
          </thead>
          <tbody>{renderListeners()}</tbody>
        </table>
      </div>
    </>
  );
};

export default ListenersList;
