import { useState } from 'react';
import { request } from '../../utils/network-client';
import RecordingRow from './RecordingRow';
import Spinner from '../Spinner';

const Recordings = ({ streamName }) => {
  const [loading, setLoading] = useState(false);
  const [recordings, setRecordings] = useState([]);

  const fetchRecordings = () => {
    setLoading(true);
    request('get', `recordings/${streamName}`)
      .then((resp) => {
        setRecordings(resp.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderRecordings = () => {
    return recordings?.map((recording, index) => {
      return <RecordingRow key={index} recording={recording} />;
    });
  };

  return (
    <>
      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#myModal" onClick={fetchRecordings}>
        Recordings
      </div>

      <div className="modal fade" id="myModal" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="myModalLabel">
                Recordings
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {loading && <Spinner />}
              {!loading && (
                <div className="table-responsive">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderRecordings()}</tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Recordings;
